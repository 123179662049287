<template>
    <div class="responses">
        <ChatWindow v-if="showChat" :responseId="chatId" :setRoomId="chatRoomId" @close="showChat = false" />

        <VBreadcrumbs v-if="$route.params.id" :customRouteIndex="1" :customRouteLink="`/vacancies/${$route.params.id}`"
            class="responses__breadcrumbs" />
        <MainHeader class="responses__header" :class="{ 'responses__header_vacancy': $route.params.id }"
            v-if="responsesList.responses" :title="'Задачи'"
            :number="responsesList.responses.list.length ? responsesList.responses.count : ''" />

        <div class="responses__row">
            <VSegmentedControl :items="filteringOptions" :modelValue="activeFilter" @update:modelValue="filtration"
                class="responses__segmented-control"
                :class="{ 'responses__segmented-control_full': route.meta.role !== 'HR' }" />

            <VDefaultInput v-model="search" @update:modelValue="responsesSearch"
                placeholder="Введите вакансию, фио, место работы" border class="responses__search">
                <template #iconLeft>
                    <img src="@/assets/images/icons/search.svg" alt="search" />
                </template>
                <template #iconRight>
                    <div class="companies__search-clear" v-if="search" @click="clearSearch">
                        <img src="@/assets/images/icons/x.svg" alt="search" />
                    </div>
                </template>
            </VDefaultInput>
        </div>

        <div class="responses__content-wrapper">
            <ResponseSidebar class="responses__sidebar" v-if="responsesList?.responses" :responsesData="responsesList"
                :role="role" v-model="sidebarFilters" />
            <div class="responses__content" v-if="responsesList.responses">
                <ResponseHeaderSort class="responses__response-header-sort" :modelValue="sortOrder"
                    @update:modelValue="onSortOrderUpdate" />
                <div class="responses__cards-wrapper" v-if="responsesList.responses.list.length">
                    <div class="responses__cards">
                        <ResponseCard class="responses__card" v-for="response in responsesList.responses.list"
                            :key="response.id" :response="response" @openApprovalHistory="openApprovalHistory"
                            @openChat="openChat(response.id)" />
                    </div>
                </div>

                <div class="responses__no-content" v-if="!responsesList.responses.list.length">
                    <VNoContent v-if="!search" :title="textNoContent"
                        description="По выбранному фильтру ничего не найдено" />
                    <VNoContent v-if="search" title="Ничего не найдено"
                        description="Проверьте пожалуйста, правильно ли вы написали название" />
                </div>
                <div class="responses__pagination" v-if="responsesList.responses.pages_count > 1 && showPagination">
                    <VButton class="responses__pagination-btn" label="Загрузить еще" bg="#0D5FCB1A" color="#0D5FCB"
                        bgHover="#0D5FCB4D" colorHover="#0D5FCB" @click="loadMore()"
                        v-if="page < responsesList.responses.pages_count && showPagination" />
                    <VPaginate :pageCount="responsesList.responses.pages_count" :modelValue="page"
                        @update:modelValue="pagination" class="responses__pagination-pag" />
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
import { ref, watch, onActivated } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import responses from "@/api/responses";

import MainHeader from "@/components/MainHeader";
import VPaginate from "@/components/UI/VPaginate";
import ResponseCard from "@/components/ResponseCard";
import ChatWindow from '@/components/ChatWindow'
import ResponseSidebar from '@/components/ResponseSidebar'
import ResponseHeaderSort from '@/components/ResponseHeaderSort'
import { debounce } from "@/services/helpers"
const route = useRoute();

const store = useStore();

const role = store.getters["auth/role"];

const vacancyId = ref(route.params.id)
const filteringOptions = ref([]);

const showApprovalHistoryModal = ref(false)
const approvalHistoryId = ref(null)
const showChat = ref(false)
const chatId = ref(null)
const chatRoomId = ref(null)
const response = ref(null)
const textNoContent = ref('Список откликов пуст')


const activeFilter = ref("all");
const showPagination = ref(true)

const search = ref("");
const page = ref(1);
const sidebarFilters = ref({
    stepFilterId: null,
    approvalId: null,
    vacancyId: 'all',
    isAccepted: null,
    isCancelled: null
})
const sortOrder = ref({})
if (route.meta.role !== 'HR') textNoContent.value = 'Список задач пуст'

const idToLabelsMap = ref({
    "all": "Все",
    "is_new": "Необработанные",
    "is_deadline": "Срок истёк",
    "is_worked_out": "Отработанные"
})

if (role === 'HR') {
    filteringOptions.value = [
        {
            id: "all",
            label: "Все",
        },
        {
            id: "is_new",
            label: "Необработанные",
        },
        {
            id: "is_deadline",
            label: "Срок истёк",
        },
        {
            id: "is_worked_out",
            label: "Отработанные",
        },
    ]
} else if (role === 'APPROVING_PERSON') {
    filteringOptions.value = [
        {
            id: "all",
            label: "Все",
        },
        {
            id: "is_new",
            label: "Необработанные",
        },
        {
            id: "is_deadline",
            label: "Срок истёк",
        },
        {
            id: "is_worked_out",
            label: "Отработанные",
        },
    ]
} else {
    filteringOptions.value = [
        {
            id: "all",
            label: "Все",
        },
        {
            id: "is_new",
            label: "Новые",
        },
        {
            id: "is_deadline",
            label: "Дедлайн",
        },
    ]
}


const openApprovalHistory = (id) => {
    approvalHistoryId.value = id
    showApprovalHistoryModal.value = true
}

const fetchResposne = async (id) => {
    return response.value = await responses.getResponse(id)
}
const formatHeaderFilters = (responsedFilterHeaders = {}) => {
    const headerFilters = Object.entries(responsedFilterHeaders).map(([key, val]) => {
        return {
            id: key,
            label: idToLabelsMap.value[key],
            count: val?.all,
            incoming: val?.not_viewed
        }
    })
    filteringOptions.value = role !== 'APPROVING_PERSON' ? [{
        id: "all",
        label: "Все",
    }, ...headerFilters, {
        id: "is_worked_out",
        label: "Обработанные",
    },] : [{
        id: "all",
        label: "Все",
    }, ...headerFilters]
    const indexOfIgnoring = filteringOptions.value.findIndex((filter) => filter.id === 'is_delayed')
    if (indexOfIgnoring) {
        filteringOptions.value.splice(indexOfIgnoring, 1)
    }
}
const fetchResponses = async (outerParams) => {
    const params = {
        ...(outerParams && outerParams),
        search: search.value ? search.value : "",
        page: page.value,
        ...(activeFilter.value && { [activeFilter.value]: 1 }),
        ...(sidebarFilters.value?.vacancyId && { vacancy_id: sidebarFilters.value?.vacancyId }),
        ...(sidebarFilters.value?.approvalId && { filter_approval_id: sidebarFilters.value?.approvalId }),
        ...(sidebarFilters.value?.stepFilterId && { filter_step_id: sidebarFilters.value?.stepFilterId }),
        ...(sidebarFilters.value?.isAccepted && { is_accepted: sidebarFilters.value?.isAccepted }),
        ...(sidebarFilters.value?.isCancelled && { is_cancelled: sidebarFilters.value?.isCancelled }),

    };



    try {
        const response = await responses.getResponses(params, sortOrder.value?.sort)

        responsesList.value = response;
        formatHeaderFilters(response.filter_header)
        showPagination.value = true
    } catch (error) {
        console.log(error);
    }
}
const loadMore = async (num) => {
    showPagination.value = false
    page.value = num ? num : page.value + 1;

    const params = {
        search: search.value ? search.value : "",
        page: page.value,
        order: sortOrder.value,
        ...(sidebarFilters.value?.vacancyId && { vacancy_id: sidebarFilters.value?.vacancyId }),
        ...(sidebarFilters.value?.approvalId && { filter_approval_id: sidebarFilters.value?.approvalId }),
        ...(sidebarFilters.value?.stepFilterId && { filter_step_id: sidebarFilters.value?.stepFilterId }),
        ...(sidebarFilters.value?.isAccepted && { is_accepted: sidebarFilters.value?.isAccepted }),
        ...(sidebarFilters.value?.isCancelled && { is_cancelled: sidebarFilters.value?.isCancelled }),

    };

    try {
        const response = await responses.getResponses(params, sortOrder.value?.sort)
        formatHeaderFilters(response.filter_header)
        if (num) responsesList.value.responses.list = [];
        responsesList.value.responses.list.push(...response.responses.list);
        showPagination.value = true
    } catch (error) {
        console.log(error);
    }
}

const responsesList = ref({})
const getResponses = async (params) => {
    try {
        const response = await responses.getResponses(params)
        responsesList.value = response
        formatHeaderFilters(response.filter_header)


    } catch (error) {
        console.log(error)
    }
}

const denouncedGetResponses = debounce((params) => getResponses(params), 300)
const responsesSearch = () => {
    if (search.value.length < 3) {
        const params = {
            search: '',
        };
        if (vacancyId.value) params.vacancy_id = vacancyId.value

        if (activeFilter.value !== "all") params[activeFilter.value] = 1
        denouncedGetResponses(params)
        return
    }
    const params = {
        search: search.value,
    };
    if (vacancyId.value) params.vacancy_id = vacancyId.value

    if (activeFilter.value !== "all") params[activeFilter.value] = 1
    denouncedGetResponses(params)
};

const filtration = (val) => {
    if (val === activeFilter.value) return;
    activeFilter.value = val;
    search.value = "";
    page.value = 1;

    const params = {
        [val]: 1
    };




    if (vacancyId.value) params.vacancy_id = vacancyId.value

    if (sidebarFilters.value.isAccepted || sidebarFilters.value.isCancelled) {
        sidebarFilters.value.isAccepted = 0
        sidebarFilters.value.isCancelled = 0
    }
    fetchResponses()
};

const pagination = (val) => loadMore(val);

const clearSearch = () => {
    search.value = "";

    const params = {};
    if (vacancyId.value) params.vacancy_id = vacancyId.value
    if (activeFilter.value !== "all") params[activeFilter.value] = 1
    getResponses(params)
};

const openChat = (id) => {
    fetchResposne(id).then((data => {
        chatId.value = id
        chatRoomId.value = response.value?.chat_room_id
        showChat.value = true
    }))
}

const onSortOrderUpdate = (sort, field) => {
    sortOrder.value = { sort, field }
    fetchResponses()
}
watch(sidebarFilters, () => {


    if (activeFilter.value !== 'all' && (sidebarFilters.value?.stepFilterId ||
        sidebarFilters.value?.approvalId ||
        sidebarFilters.value?.isAccepted ||
        sidebarFilters.value?.isCancelled ||
        (!sidebarFilters.value?.vacancyId || sidebarFilters.value?.vacancyId !== 'all'))) {
        activeFilter.value = 'all'
    }


    fetchResponses()
}, { deep: true })

watch(() => route.path, () => {
    if (route.path === '/responses') {
        fetchResponses()
    }
});

onActivated(() => {
    if (route.params.id) {
        sidebarFilters.value = route.params.id
        fetchResponses({
            vacancy_id: route.params.id
        })
    } else {
        if (route.query?.vacancyId) {
            sidebarFilters.value.vacancyId = route.query?.vacancyId
            fetchResponses({ vacancy_id: route.query?.vacancyId })
        } else {
            fetchResponses()
        }
    }

})

</script>

<style scoped lang="scss">
.responses {
    padding-bottom: 57px;

    &__breadcrumbs {
        margin-top: 24px;
    }

    &__header {
        margin-top: 32px;

        &_vacancy {
            margin-top: 16px;
        }
    }

    &__row {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    &__segmented-control {
        &::v-deep(.v-filtration__filter) {
            width: 182px;
            margin-left: 0;
        }

        &_full {
            &::v-deep(.v-filtration__filter) {
                width: 202.33px;
            }
        }
    }

    &__search {
        width: 352px;

        &::v-deep(.companies__search-clear) {
            height: 20px !important;
        }

        &::v-deep(.v-input__input) {
            &::placeholder {
                color: $dark-blue;
                opacity: 1;
            }
        }


    }

    &__content {
        width: 832px;
    }

    &__cards {
        margin-left: 32px;
        width: 100%;
        height: calc(100% - 206px);
        margin-top: 12px;
        display: flex;
        flex-direction: column;
    }

    &__card {
        width: 100%;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    &__no-content {
        width: 100%;
        height: calc(100vh - 56px - 32px - 34px - 24px - 48px - 32px - 40px - 57px - 57px);
        max-height: calc(475px - 40px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__pagination {
        position: relative;
        width: 100%;
        height: 48px;
        margin-top: 32px;
        display: flex;
        justify-content: center;
    }

    &__pagination-btn {
        width: 156px;
        padding: 0 10px;
        border-radius: 8px;

        &:active {
            background-color: #0D5FCB80 !important;
            color: $blue !important;
        }
    }

    &__pagination-pag {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__approval-history-modal {
        &::v-deep(.modal__body) {
            width: 626px;
        }
    }

    &__content-wrapper {
        margin-top: 32px;
        display: flex;
    }

    &__sidebar {
        width: 256px;
    }

    &__response-header-sort {
        margin-left: 32px;
    }
}
</style>
